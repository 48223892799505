import Image, { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder/ImageWithPlaceholder';

export type TPartnersWidgetCardData = {
  imageProps?: ImageProps | null;
  iconProps?: ImageProps | null;
  linkProps?: LinkProps | null;
  description: string;
};

type TPartnersWidgetCardProps = {
  card: TPartnersWidgetCardData;
};

export const PartnersWidgetCard: React.FC<TPartnersWidgetCardProps> = ({
  card,
}) => {
  const { iconProps, imageProps, linkProps, description } = card;
  const imageKey =
    typeof imageProps?.src === 'string' ? imageProps.src : undefined;
  const cardEl = (
    <div className='group relative flex h-[18.75rem] min-w-[18.5rem] max-w-[18.5rem] flex-col rounded-2xl border border-control-250 hover:border-control-600 md:max-w-[85vw]'>
      {iconProps && (
        <div className='absolute -top-2.5 left-1/2 size-5 -translate-x-1/2 bg-interface-50'>
          <Image
            {...iconProps}
            alt={iconProps.alt}
            className='size-5 opacity-[.15] group-hover:opacity-40'
          />
        </div>
      )}

      {imageProps && (
        <div className='flex flex-1 justify-center px-4'>
          <div className='mt-20 flex h-[7.75rem] w-[12.5rem] justify-center'>
            <ImageWithPlaceholder key={imageKey} {...imageProps} />
          </div>
        </div>
      )}

      <div className='leading-7 overflow-hidden break-words p-3.5 text-center text-xs font-medium uppercase tracking-[0.28rem] text-interface-500 group-hover:text-control-650'>
        <div>{description}</div>
      </div>
    </div>
  );

  if (linkProps) {
    return <Link {...linkProps}>{cardEl}</Link>;
  }

  return cardEl;
};
